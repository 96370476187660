import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const About = props => {
  return (
    <Layout {...props}>
      <Seo title='Our Team' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='About Us'
          subheader='Please read the following.'
        />
      </Stack>
      <Stack>
        <Main>
        <p>Blood Sugar Help is a news site about Blood Sugar Research. When the studies work,&nbsp;these knowledge will help us overcome blood sugar issues in our bodues.</p>
        
        <p>Our aim is to empower people the knowledge about blood sugar that is easy and simple to read, cutting out other non essentials in a research paper and promoting the key important notes within it</p>
        
        <p>We are a News site. We do not proclaim that we have a cure or relief. We do not tell you what you should and should not do. If we report on the harmful effects of supplements, habits, or exercise or any other conditions proclaimed by a study, we do not say that you &lsquo;therefore&rsquo; shouldn&rsquo;t take do. If we report on a study that says the risks are minimal, we do not say that the risks are &lsquo;therefore&rsquo; safe.</p>
        
        <p>Blood Sugar Help is&nbsp;<em>not</em>&nbsp;about proclaiming cure or relief and does&nbsp;<em>not</em>&nbsp;have a Message. There are enough people in the world who have already proclaiming to be true. Most of them are highly dangerous. Blood Sugar Help does not believe in proclaiming cure. We believe in intelligent people who are open to learning about new insights, and who can work out for themselves what is of value and leave the rest for what it is.</p>
        
      
        </Main>
      </Stack>
    </Layout>
  )
}

export default About
